import React from 'react'
import { Link } from 'gatsby'

import Image from './Image'

import './Footer.css'

export default ({ contactDetails, social }) => (
  <section className="footer black">
    <div className="wide quart flex">
      <div className="item logo">
        <Image src="/images/logo-full.svg" alt="Mi Design Studio" />
      </div>
      <div className="item">
        <h6>Contact</h6>
        <p>{contactDetails.address}</p>
        <a href={`tel:${contactDetails.phone}`}>{contactDetails.phone}</a>
        <a href={`mailto:${contactDetails.email}`}>{contactDetails.email}</a>
        <div className="social">
          <a href={social.instagram}>
            <i className="fab fa-instagram" />
          </a>
          <a href={social.facebook}>
            <i className="fab fa-facebook-f" />
          </a>
          <a href={social.houzz}>
            <i className="fab fa-houzz" />
          </a>
        </div>
      </div>
      <div className="item">
        <h6>Projects</h6>
        <Link to="/post-categories/custom-homes">Custom Homes</Link>
        <Link to="/post-categories/multi-residential">Multi Residential</Link>
        <Link to="/post-categories/commercial">Commercial</Link>
        <Link to="/post-categories/concepts">Concepts</Link>
        <Link to="/post-categories/luxury-homes">Luxury Homes</Link>
        <Link to="/post-categories/renovations">Renovations</Link>
      </div>
      <div className="item">
        <h6>Information</h6>
        <Link to="/about">About</Link>
        <Link to="/services">Services</Link>
        <Link to="/contact">Contact</Link>
      </div>
    </div>
    <div className="thin center">
      <p className="copyright">
        Copyright © Mi Design Studio. Site by{' '}
        <a href="https://thriveweb.com.au/">Thrive Digital</a>
      </p>
    </div>
  </section>
)
