import React, { Component } from 'react'
import { Location } from '@reach/router'
import _throttle from 'lodash/throttle'
import { Link } from 'gatsby'
import Logo from './Logo'

import './Nav.css'

export class Navigation extends Component {
  state = {
    active: false,
    currentPath: false,
    navActive: null
  }

  componentDidMount = () => {
    window.addEventListener('scroll', _throttle(this.calcNavPosition, 50))
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', _throttle(this.calcNavPosition, 50))
  }

  calcNavPosition = () => {
    if (window.pageYOffset > 20 && !this.state.navActive) {
      this.setState({
        navActive: true
      })
    } else if (window.pageYOffset < 20 && this.state.navActive) {
      this.setState({
        navActive: null
      })
    }
  }

  handleMenuToggle = () => this.setState({ active: !this.state.active })

  // Only close nav if it is open
  handleLinkClick = () => this.state.active && this.handleMenuToggle()

  toggleSubNav = subNav =>
    this.setState({
      activeSubNav: this.state.activeSubNav === subNav ? false : subNav
    })

  render() {
    const { active, navActive } = this.state,
      NavLink = ({ to, className, children, ...props }) => (
        <Link
          to={to}
          className={`NavLink ${
            to === this.state.currentPath ? 'active' : ''
          } ${className}`}
          onClick={this.handleLinkClick}
          {...props}
        >
          {children}
        </Link>
      )

    return (
      <nav
        className={`nav ${active ? 'nav-active' : ''} ${
          navActive ? 'scroll' : ''
        }`}
      >
        <div className="wide flex">
          <Link to="/" onClick={this.handleLinkClick}>
            <Logo />
          </Link>
          <div className="flex">
            <div className="links flex">
              <NavLink to="/">Home</NavLink>
              <NavLink to="/projects">Projects</NavLink>
              <NavLink to="/about">About</NavLink>
              <NavLink to="/services">Services</NavLink>
              <NavLink to="/contact">Contact</NavLink>
              <a href="tel:(07) 55 767 118" className="NavLink phone">(07) 55 767 118</a>
            </div>
            <div className="bun">
              <button className="hamburger" onClick={this.handleMenuToggle}>
                menu
              </button>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default ({ subNav, contactDetails }) => (
  <Location>{route => <Navigation subNav={subNav} {...route} />}</Location>
)
