import React from 'react'
import { Link } from 'gatsby'

import Image from './Image'
import './PageHeader.css'

const PageHeader = ({ title, backgroundImage, link }) => (
  <div className="hero relative">
    <div className="absolute">
      <div className="wide">
        <h1>{title}</h1>
        {!!link && (
          <Link to={link} className="link">
            Enquire now
          </Link>
        )}
      </div>
    </div>
    <Image
      background
      resolutions="large"
      src={backgroundImage}
      alt={title}
      size="cover"
    />
  </div>
)

export default PageHeader
