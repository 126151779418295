import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Meta from './Meta'
import Nav from './Nav'
import Footer from './Footer'

import 'modern-normalize/modern-normalize.css'
import './globalStyles.css'

export default ({ children, meta, title }) => {
  return (
    <StaticQuery
      query={graphql`
        query IndexLayoutQuery {
          settingsYaml {
            siteTitle
            siteDescription
            googleTrackingId
            contactDetails {
              address
              phone
              email
            }
            social {
              instagram
              facebook
              houzz
            }
            socialMediaCard {
              image
            }
          }
          allPosts: allMarkdownRemark(
            filter: { fields: { contentType: { eq: "postCategories" } } }
            sort: { order: DESC, fields: [frontmatter___title] }
          ) {
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  title
                }
              }
            }
          }
        }
      `}
      render={data => {
        const {
          siteTitle,
          socialMediaCard,
          googleTrackingId,
          contactDetails,
          social
        } = data.settingsYaml || {}

        return (
          <Fragment>
            <Helmet
              defaultTitle={siteTitle}
              titleTemplate={`%s | ${siteTitle}`}
            >
              {title}
              <link href="https://ucarecdn.com" rel="preconnect" crossorigin />
              <link rel="dns-prefetch" href="https://ucarecdn.com" />
              <link
                rel="stylesheet"
                href="https://use.typekit.net/lfn6kyg.css"
              />
              <link
                rel="stylesheet"
                href="https://use.fontawesome.com/releases/v5.7.0/css/all.css"
                integrity="sha384-lZN37f5QGtY3VHgisS14W3ExzMWZxybE1SJSEsQp9S+oqd12jhcu+A56Ebc1zFSJ"
                crossorigin="anonymous"
              />
              <meta
                name="google-site-verification"
                content="SzHZDWr9eSB4c9jeqOTv3GtytbXBUCSSF2yhu25n7Q4"
              />
              <meta
                name="msvalidate.01"
                content="B0112812CA622A13C327AF572E2BA5B5"
              />
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=UA-57279257-7"
              />
              <script>
                {`window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'UA-57279257-7');`}
              </script>
            </Helmet>

            <Meta
              googleTrackingId={googleTrackingId}
              absoluteImageUrl={
                socialMediaCard &&
                socialMediaCard.image &&
                socialMediaCard.image
              }
              {...meta}
              {...data.settingsYaml}
            />

            <Nav />

            <Fragment>{children}</Fragment>

            <Footer contactDetails={contactDetails} social={social} />
          </Fragment>
        )
      }}
    />
  )
}
